import {
	createRouter,
	createWebHistory
} from "vue-router";

const routes = [
	{
		path:"/",
		redirect:"/home"
	},
	{
    	path: '/home',
    	name: 'home',
    	meta: {
    		title: '首页'
    	},
    	component: () => import('../views/Index.vue')
    },
    {
    	path: '/login',
    	name: 'login',
    	meta: {
    		title: '登录'
    	},
    	component: () => import('../views/Login.vue')
    },
	{
		path: '/profile',
		name: 'profile',
		meta: {
			title: '用户中心'
		},
		component: () => import('../views/Profile.vue')
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

router.beforeEach((to, from, next) => {
	
	// 配置标题
	document.title = "TK";
	
	// 判断是否登录
	const token = localStorage.getItem('token');
	if(token){
		next();
	}else{
		if(to.path !== '/home' && to.path !== '/login'){
			next('/login');
		}else{
			next();
		}
	}
});

export default router;

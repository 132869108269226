// import Vue from 'vue'
import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './assets/css/icon.css'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// 自定义主题色
import './assets/theme/index.css'

// 粘贴功能
import VueClipboard from 'vue-clipboard2'

// icon
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App)

// 注册全局组件
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app
.use(store)
.use(router)
.use(VueClipboard)
.use(ElementPlus)
.mount('#app')

import * as socketApi from './utils/socket'

app.config.globalProperties.socketApi = socketApi;

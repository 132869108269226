export const uploadUrl = 'https://192.168.50.103:8000/api/upload/upload_image';

export const baseUrl = 'https://api.twittertking.com/';

export const websocketUrl = "ws://api.twitterkk.com/ws/chat/";

// export const uploadUrl = 'http://192.168.50.104:8000/api/upload/upload_image';

// export const baseUrl = 'http://127.0.0.1:8000/';

// export const websocketUrl = "ws://192.168.50.104:8000/ws/chat/";

